<template>
  <v-app
    :style="{
      background: this.$vuetify.theme.themes.light.backgroundColor,
    }"
  >
    <router-view />
    <AutoLogout />

  </v-app>
</template>

<script>
// import { datadogLogs as datadog } from "@datadog/browser-logs";

import { useAuthStore } from "./store/auth";
import AutoLogout from "@/components/AutoLogout.vue";

export default {
  name: "App",
  setup() {
    const stateVals = useAuthStore();

    return {
      stateVals
    };
  },

  components: {
    AutoLogout
  },

  data() {
    return {
      primary: null,
      secondary: null,
      fetchedData: false,
      employerID: null,
      auth: this.$auth,
      isLoadingProduct: false
    };
  },
  // created(){

  // },
  mounted() {
    // this.stateVals.fetchGetThemeByEmployerID(
    //   this.stateVals.employerId,
    //   this.stateVals.idToken   
    // ).then(() => {
    //   // this.primary = response.data.primaryColor;
    //   // this.secondary = response.data.secondaryColor;
    //   this.fetchedData = true;
    //   // this.employerID = this.response.data.employerId;
    // })
    // Fetching Theme Color according to user log in
    // datadog.init({
    //   clientToken: "pubd1fb343c89acb6c03229b5d73f836dfb",
    //   site: "datadoghq.com",
    //   forwardErrorsToLogs: true,
    //   sampleRate: 100,
    // });
  }
};
</script>
