import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";

export const fetchSubdomainInfoByClientName = (clientname, idToken) => {
  
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SUBDOMAIN_BY_CLIENTNAME, {
          params: {
            clientname,
          },
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
            Authorization: `Bearer ${idToken}`,
          },
        })
        .then((response) => {
          datadogLogs.logger.info(
            `Called GetSubdomainInfoByClientName for clientname=${clientname} Response : ${response}`
          );
          resolve(response);
        })
        .catch((err) => {
          datadogLogs.logger.error(
            `Called GetSubdomainInfoByClientName for clientname=${clientname} Response : ${err}`
          );
          reject(err);
        });
    });
  };