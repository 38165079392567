import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { useAuthStore } from "@/store/auth";
// api called for fetch userDetails in registerpage

export const createMemberRegistrationCode = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_CREATE_MEMBER_REGISTRATION_CODE, {
        params: {
          memberEmail: email,
          step: 0,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Create member Registration Code Error : ${err}`
        );
        reject(err);
      });
  });
};

export const completeMemberRegistrationCode = (
  email,
  authToken,
  registerId
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_COMPLETE_MEMBER_REGISTRATION_CODE, {
        params: {
          memberEmail: email,
          registrationCode: registerId,
          step: 0,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Create member Registration Code Error : ${err}`
        );
        reject(err);
      });
  });
};

export const addDependentMember = (member, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GET_ADD_MEMBER, member, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called Add dependent for Response : ${err}`
        );
        reject(err);
      });
  });
};

export const getSearchMedication = (searchTerm, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_SEARCH_MEDICATIONS_URL, searchTerm, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called SearchFormulary for Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchStructureData = (medication, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_DRUG_STRUCTURE, {
        params: {
          SEOName: medication,
        },
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called fetchStructureData for search=${medication} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchMedicationDetails = (medication, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_SEARCH_MEDICATIONS_DETAILS_URL, medication, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetDrugFromSearch for Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchSearchDrugs = (medication, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_REGISTER_GET_NDC_RXSENSE, {
        params: {
          Query: medication,
          MaxResults: 6,
        },
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called fetchSearchDrugs for search=${medication} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchMedicationDetailsRXSense = (
  ndcNumber,
  zipcode,
  quantity,
  idToken
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_REGISTER_GET_TIERED_PRICING, {
        params: {
          NDC: ndcNumber,
          Quantity: quantity,
          ZipCode: zipcode,
          requested_option: 1,
        },
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called fetchMedicationDetailsRXSense for Price=${ndcNumber} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchUserData = (email, dateOfBirth) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_VALIDATE_USER_URL, {
        emailid: email,
        dob: dateOfBirth,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called ValidateMember for email=${email} Response : ${err}`
        );
        reject(err);
      });
  });
};
export const fetchUserDataWithID = ( dateOfBirth, id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_VALIDATE_USER_URL, {
        emailid: null,
        dob: dateOfBirth,
        memberID: id
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called ValidateMember for email=${id} Response : ${err}`
        );
        reject(err);
      });
  });
};
export const updateMember = (data, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GET_UPDATE_MEMBER, data, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(`Called update member Response : ${err}`);
        reject(err);
      });
  });
};

export const updateMemberAll = (data, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GET_UPDATE_ALL_MEMBERS, data, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(`Called Update All member Response : ${err}`);
        reject(err);
      });
  });
};

export const sendMemberDetailsForEmployeeSelfSignup = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GET_CREATE_MEMBER_SELF_SIGNUP, data)
      .then((response) => {
        datadogLogs.logger.info(`Called Create Member Response : ${response}`);
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(`Called Create Member Response : ${err}`);
        reject(err);
      });
  });
};

export const validateEmployeeSelfSignupInvitationToken = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_CREATE_MEMBER_INVITATION_TOKEN_VERIFY, {
        params: {
          token: token,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Can not verify member invitation token : ${err}`
        );
        reject(err);
      });
  });
};

//Calling Themes Parameter

export const fetchThemeData = (id, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_THEME_URL, {
        params: {
          employerID: id,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          //TODO: Fix Typo
          `Called GetThemeByEmoloyerID for employerId=${id} Response : ${err}`
        );
        reject(err);
      });
  });
};

//Validate member for self service

export const validateSelfSignup = (
  enrollmentCode,
  firstName,
  lastName,
  email
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_VALIDATE_SELF_SIGNUP_URL, {
        enrollmentCode: enrollmentCode,
        firstName: firstName,
        lastName: lastName,
        email: email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called ValidateSelfSignnup for email=${email} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const validateEnrollmentCode = (enrollmentCode) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_VALIDATE_ENROLLMENT_CODE_URL, {
        enrollmentCode: enrollmentCode,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called validateEnrollment for code = ${enrollmentCode} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const findMemberStatus = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_FIND_MEMBER_STATUS_URL, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(`Called find member status : ${err}`);
        reject(err);
      });
  });
};
//Send Data to Chargify

export const sendUserData = (idToken,data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_SUBMIT_USERDETAILS_URL, data,{
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(`Called MemberSignup Response : ${err}`);
        reject(err);
      });
  });
};

//Getting Employer Id after Login to fetch apis

export const fetchEmployerId = (email, idToken) => {
  const auth = useAuthStore();

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_EMPLOYERID_URL, {
        params: {
          email: email,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        auth.customerType = response.data.data.customerType;
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetEmployerAndSKUID for email=${email} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchCategoryAction = (memberID, idToken) => {

  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_ENABLE_GET_CATEGORY_ACTION, {
        params: { memberID },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called VUE_APP_ENABLE_GET_CATEGORY_ACTION for member=${memberID} Response : ${err}`
        );
        reject(err);
      });
  });
};

// Calling Products on Registration

export const fetchRegistrationProducts = (employerId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_REGISTER_PRODUCT_URL, {
        params: {
          employerID: employerId,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetProductStockByEmployer for employerId=${employerId} Response : ${err}`
        );
        reject(err);
      });
  });
};

// FETCH THEME FROM API BY EMAIL

export const fetchEmployerAttributes = (employerId, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_EMPLOYER_ATTRIBUTES_URL, {
        params: {
          employerID: employerId,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetEmployerProfileEditAttributes for employerID=${employerId} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchLogoDynamic = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_THEME_BY_EMAIL, {
        params: {
          email: email,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called fetchLogoDynamic for email=${email} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const getEmployerSystemAttributes = (employerID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_EMPLOYER_SYSTEM_ATTRIBUTES, {
        params: {
          employerID: employerID
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `getEmployerSystemAttributes Error : ${err}`
        );
        reject(err);
      });
  });
};

//Employer Custom Fields
export const fetchEmployerCustomFields = (employerId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_EMPLOYER_CUSTOM_FIEDS, {
        params: {
          employerID: employerId,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called fetchEmployerCustomFields for employerId=${employerId} Response : ${err}`
        );
        reject(err);
      });
  });
};