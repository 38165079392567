import Vue from "vue";
import App from "./App.vue";
import router from "./routes/routes";
import "./assets/styles/index.css";
import vuetify from "@/plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueCompositionAPI from "@vue/composition-api";
import { clientId, audience } from "../auth_config";
import { Auth0Plugin } from "./auth";
import Maska from "maska";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
// Font awsome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import vueCountryRegionSelect from "vue-country-region-select";
import axios from "axios"
import VueAxios from "vue-axios"
import {VTextField} from 'vuetify/lib'
import {VSelect} from 'vuetify/lib'

// import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component('v-text-field', VTextField);
Vue.component('v-select', VSelect);

const VueTelInputOptions = {
  mode: "international",
  onlyCountries: ["US", "CA", "PR", "VI"],
  defaultCountry: "US",
  autoFormat: true,
  validCharactersOnly: true,
};
const getCurrentDomain = () => {
  if(window.location.href.indexOf("prodigyrxlogi") > -1 || window.location.href.indexOf("pattycarehealt") > -1 || window.location.href.indexOf("epiquehealt") > -1 
      || window.location.href.indexOf("membertes") > -1 || window.location.href.indexOf("mylivedo") > -1 || window.location.href.indexOf("gotobenefi") > -1)
    return "login.yourbenefitportal.com";
  else
    return "revive-prod.us.auth0.com";
};
const domain = getCurrentDomain();
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
Vue.use(Maska)
  .use(PiniaVuePlugin)
  .use(VueCompositionAPI)
  .use(vueCountryRegionSelect)
  .use(VueTelInput, VueTelInputOptions)
  .use(VueAxios, axios)
  .use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
  });
Vue.config.productionTip = false;
new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
